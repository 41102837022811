import { useEffect, useState, useRef } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { Column, Table, AutoSizer } from 'react-virtualized';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import 'react-virtualized/styles.css';
import './style.css';

const CustomizedLabel = (props) => {
  const { x, y, stroke, value } = props;

  return (
    <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
      {value}
    </text>
  );
};

const renderTooltipContent = (value, name, props) => {
  return `${value} (Classifica: ${props.payload.class})`;
};

export default function Player() {
  const navigate = useNavigate();
  let { playerId } = useParams();
  const [data, setData] = useState([]);
  const [id, setId] = useState(playerId);
  const [loading, setLoading] = useState(true);
  const [players, setPlayers] = useState([]);
  const [playerInfo, setPlayerInfo] = useState();
  const [globalRanking, setGlobalRanking] = useState([]);
  const [regionRanking, setRegionRanking] = useState([]);
  const [clubRanking, setClubRanking] = useState([]);
  const [globalIndex, setGlobalIndex] = useState();
  const [regionIndex, setRegionIndex] = useState();
  const [clubIndex, setClubIndex] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const globalTableRef = useRef();
  const regionTableRef = useRef();
  const clubTableRef = useRef();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); 

  useEffect(() => {
    getPlayer(id);
  }, [id]);

  useEffect(() => {
    requestAnimationFrame(function() {
      if (globalTableRef.current && regionTableRef.current && clubTableRef.current) {
        globalTableRef.current.scrollToRow(globalIndex + 8);
        regionTableRef.current.scrollToRow(regionIndex + 8);
        clubTableRef.current.scrollToRow(clubIndex + 8);
      }
    });
  }, [globalIndex, regionIndex, clubIndex]);

  const getPlayer = (id) => {
    fetch(`http://localhost:3000/${id}`)
      .then(res => res.json())
      .then(r => {
        setPlayerInfo(r.info);
        setGlobalRanking(r.globalRanking);
        setRegionRanking(r.regionRanking);
        setClubRanking(r.clubRanking);
        setGlobalIndex(r.globalRanking.findIndex((rank) => rank.id === r.info.id));
        setRegionIndex(r.regionRanking.findIndex((rank) => rank.id === r.info.id));
        setClubIndex(r.clubRanking.findIndex((rank) => rank.id === r.info.id));
        setData(r.historicRanking);
        setLoading(false);
      });
  }

  const generateKey = (width, height, k) => `key-${width}x${height}x${k}`;

  const handleOnSearch = (term) => {
    fetch(`http://localhost:3000/search/${term}`)
      .then(res => res.json())
      .then(r => setPlayers(r));
  }

  const handleOnSelect = (item) => {
    setLoading(true);
    setId(item.id);
    navigate("/player/" + item.id);
  }

  const searchPlayer = (id) => {
    setLoading(true);
    setId(id);
  }

  const goHome = () => {
    navigate('/');
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (loading) {
    return (
      <main className="screen">
        <div className='spinner'>
          <TailSpin
            height="100"
            width="100"
            color="#555555"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass="m-t-50"
            className="spinner"
          />
        </div>
      </main>
    )
  } else {
    return(
      <main className="screen">
        {
          isModalOpen && (
          <div className="modal">
            <div className="modal-content">
              <div>
                <span className="modal-title">Storico classifica</span>
                <span className="close-button" onClick={closeModal}>&times;</span>
              </div>
              <LineChart
                width={500}
                height={300}
                data={data}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 10,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="year" height={60} padding={{ left: 30, right: 30 }} />
                <YAxis reversed={true} />
                <Tooltip formatter={renderTooltipContent} />
                <Line type="monotone" dataKey="ranking" stroke="#8884d8" label={<CustomizedLabel />} />
              </LineChart>
            </div>
          </div>)
        } 
        {
          <div>
            <div className="flex">
              <div className="logo-container">
                <h1 className="logo" onClick={() => goHome()}>Ranking FIT</h1>  
              </div>
              <div className="search-player">
                <div className="search-bar">
                  <ReactSearchAutocomplete
                    placeholder = "Ricerca giocatore"
                    showNoResultsText = "Nessun risultato"
                    items = {players}
                    onSearch = {handleOnSearch}
                    onSelect = {handleOnSelect}
                    fuseOptions = {{
                      threshold: 1,
                      ignoreLocation: true,
                      keys: [ "name" ]
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="player-header">
              <div className="player-field">
                <span className="player-title">
                  { playerInfo.name }
                  <button className="button-chart" onClick={openModal}>
                    <FontAwesomeIcon icon={faChartLine} />
                  </button>
                </span>
              </div>
              <div className="player-field">
                <div className="player-field half-width">
                  <span className="info-title">Circolo:</span>
                  <span className="info-text">{ playerInfo.club }</span>
                </div>
                <div className="player-field half-width">
                  <span className="info-title">Regione:</span>
                  <span className="info-text">{ playerInfo.region }</span>
                </div>
              </div>
              <div className="player-field">
                <div className="player-field third-width">
                  <span className="info-title">Classifica 2023:</span>
                  <span className="info-text">{ playerInfo.class2023 }</span>
                </div>
                <div className="player-field third-width">
                  <span className="info-title">Classifica 2024:</span>
                  <span className="info-text">{ playerInfo.class2024 }</span>
                </div>
                <div className="player-field third-width">
                  <span className="info-title">Coefficiente:</span>
                  <span className="info-text">{ playerInfo.coeff }</span>
                </div>
              </div>
              {
                playerInfo.notes
                ?
                  <div className="player-field width-full">
                    <span className="info-title">Note:</span>
                    <span className="info-text">{ playerInfo.notes }</span>
                  </div>
                :   
                  <div />
              }
            </div>
            <div className="player-tables-container">
              <div className="table-container">
                <h2 className="table-title">Classifica nazionale</h2>
                <div style={{ height: '80vh', width: '100%', marginTop: '25px' }}>
                  <AutoSizer key={generateKey(windowSize.width, windowSize.height, '0')}>
                    {({ height, width }) => (
                      <Table
                        key={generateKey(windowSize.width, windowSize.height, '1')}
                        ref={globalTableRef}
                        headerClassName={"table-header"}
                        gridClassName={"table-row"}
                        rowClassName={"table-row"}
                        width={width}
                        height={height}
                        headerHeight={30}
                        rowHeight={50}
                        rowCount={globalRanking.length}
                        rowGetter={({ index }) => globalRanking[index]}>
                        <Column width={500} label="Nome" dataKey="name" key={generateKey(windowSize.width, windowSize.height, '11')} cellRenderer={
                        ({ cellData, rowIndex, dataKey }) => (
                          <Link to={`/player/${globalRanking[rowIndex].id}`} onClick={() => searchPlayer(globalRanking[rowIndex].id)} className="player-link">
                            {cellData}
                          </Link>
                          )
                        } />
                        <Column width={250} label="Classifica" dataKey="class2024" key={generateKey(windowSize.width, windowSize.height, '12')} />
                        <Column width={250} label="Coefficiente" dataKey="coeff" key={generateKey(windowSize.width, windowSize.height, '13')} />
                        <Column width={250} label="Ranking" dataKey="ranking" key={generateKey(windowSize.width, windowSize.height, '14')} />
                      </Table>
                    )}
                  </AutoSizer>
                </div>
              </div>
              <div className="table-container">
                <h2 className="table-title">Classifica regionale</h2>
                <div style={{ height: '80vh', width: '100%', marginTop: '25px' }}>
                  <AutoSizer key={generateKey(windowSize.width, windowSize.height, '00')}>
                    {({ height, width }) => (
                      <Table
                        key={generateKey(windowSize.width, windowSize.height, '2')}
                        ref={regionTableRef}
                        headerClassName={"table-header"}
                        gridClassName={"table-row"}
                        rowClassName={"table-row"}
                        width={width}
                        height={height}
                        headerHeight={30}
                        rowHeight={50}
                        rowCount={regionRanking.length}
                        rowGetter={({ index }) => regionRanking[index]}>
                        <Column width={500} label="Nome" dataKey="name" key={generateKey(windowSize.width, windowSize.height, '21')} cellRenderer={
                        ({ cellData, rowIndex, dataKey }) => (
                          <Link to={`/player/${regionRanking[rowIndex].id}`} onClick={() => searchPlayer(regionRanking[rowIndex].id)}  className="player-link">
                            {cellData}
                          </Link>
                          )
                        } />
                        <Column width={250} label="Classifica" dataKey="class2024" key={generateKey(windowSize.width, windowSize.height, '22')} />
                        <Column width={250} label="Coefficiente" dataKey="coeff" key={generateKey(windowSize.width, windowSize.height, '23')} />
                        <Column width={250} label="Ranking" dataKey="region_ranking" key={generateKey(windowSize.width, windowSize.height, '24')} />
                      </Table>
                    )}
                  </AutoSizer>
                </div>
              </div>
              <div className="table-container">
                <h2 className="table-title">Classifica circolo</h2>
                <div style={{ height: '80vh', width: '100%', marginTop: '25px' }}>
                  <AutoSizer key={generateKey(windowSize.width, windowSize.height, '000')}>
                    {({ height, width }) => (
                      <Table
                        key={generateKey(windowSize.width, windowSize.height, '3')}
                        ref={clubTableRef}
                        headerClassName={"table-header"}
                        gridClassName={"table-row"}
                        rowClassName={"table-row"}
                        width={width}
                        height={height}
                        headerHeight={30}
                        rowHeight={50}
                        rowCount={clubRanking.length}
                        rowGetter={({ index }) => clubRanking[index]}>
                        <Column width={500} label="Nome" dataKey="name" key={generateKey(windowSize.width, windowSize.height, '31')} cellRenderer={
                        ({ cellData, rowIndex, dataKey }) => (
                          <Link to={`/player/${clubRanking[rowIndex].id}`} onClick={() => searchPlayer(clubRanking[rowIndex].id)}  className="player-link">
                            {cellData}
                          </Link>
                          )
                        } />
                        <Column width={250} label="Classifica" dataKey="class2024" key={generateKey(windowSize.width, windowSize.height, '32')} />
                        <Column width={250} label="Coefficiente" dataKey="coeff" key={generateKey(windowSize.width, windowSize.height, '33')} />
                        <Column width={250} label="Ranking" dataKey="club_ranking" key={generateKey(windowSize.width, windowSize.height, '34')} />
                      </Table>
                    )}
                  </AutoSizer>
                </div>
              </div>
            </div>
            <div className="footer">
              <div className="tooltip-wrap">
                <div>⒤</div>
                <div className="tooltip-content">Informazioni ricavate da <span className="underline">https://www.fitp.it/Campionati-tornei-e-classifiche/Classifiche/Info-e-documenti</span></div>
              </div>
              <a href="mailto:ranking.fitp@gmail.com" className="m-l-50 brown">Contatto</a>
            </div>
          </div>
        }
      </main>
    )
  }
}