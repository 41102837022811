import React from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';

export default function Error() {
    const navigate = useNavigate();

    const goHome = () => {
        navigate('/');
    };

    return (
        <main className="screen">
            <div>
                <h1 className="logo" onClick={() => goHome()}>Ranking FIT</h1>  
                <div className="error-title">😟</div>
                <div className="error-title">Qualcosa è andato storto</div>
                <div className="error-subtitle">Riprova più tardi</div>
            </div>
        </main>
    )
}