import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from './home.js';
import Player from './player.js';
import ErrorBoundary from './error.js';
import './style.css';

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      errorElement: <ErrorBoundary />
    },
    {
      path: "/player/:playerId",
      element: <Player />,
      errorElement: <ErrorBoundary />
    },
  ]);

  return(
    <RouterProvider router={router} />
  )
}

export default App;
