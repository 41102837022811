import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { Column, Table, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css';
import './style.css';

export default function Home() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [players, setPlayers] = useState([]);
  const [menRanking, setMenRanking] = useState([]);
  const [womenRanking, setWomMenRanking] = useState([]);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    getHomepage();
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const generateKey = (width, height, k) => `key-${width}x${height}x${k}`;

  const getHomepage = () => {
    fetch(`http://localhost:3000`)
      .then(res => res.json())
      .then(r => {
        setMenRanking(r.menRanking);
        setWomMenRanking(r.womenRanking);
        setLoading(false);
      });
  }

  const handleOnSearch = (term) => {
    fetch(`http://localhost:3000/search/${term}`)
      .then(res => res.json())
      .then(r => setPlayers(r));
  }

  const handleOnSelect = (item) => {
    navigate("/player/" + item.id);
  }

  const goHome = () => {
    navigate('/');
  };

  if (loading) {
    return (
      <main className="screen">
        <div className='spinner'>
          <TailSpin
            height="100"
            width="100"
            color="#555555"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass="m-t-50"
            className="spinner"
          />
        </div>
      </main>
    )
  } else {
    return (
      <main className="screen">
        {
          <div>
            <div className="logo-container">
              <h1 className="logo" onClick={() => goHome()}>Ranking FIT</h1>  
            </div>
            <div className="search">
              <div className="search-bar">
                <ReactSearchAutocomplete
                  placeholder = "Ricerca giocatore"
                  showNoResultsText = "Nessun risultato"
                  items = {players}
                  onSearch = {handleOnSearch}
                  onSelect = {handleOnSelect}
                  fuseOptions = {{
                    threshold: 1,
                    ignoreLocation: true,
                    keys: [ "name" ]
                  }}
                />
              </div>
            </div>
            <div className="home-tables-container">
              <div className="table-container">
                <h2 className="table-title">Top uomini classifica generale</h2>
                <div style={{ height: '80vh', width: '90%', marginTop: '25px' }}>
                  <AutoSizer key={generateKey(windowSize.width, windowSize.height, '0')}>
                    {({ height, width }) => (
                      <Table
                        key={generateKey(windowSize.width, windowSize.height, '1')}
                        headerClassName={"table-header"}
                        gridClassName={"table-row"}
                        rowClassName={"table-row"}
                        width={width}
                        height={height}
                        headerHeight={30}
                        rowHeight={50}
                        rowCount={menRanking.length}
                        rowGetter={({ index }) => menRanking[index]}>
                        <Column width={300} label="Nome" dataKey="name" key={generateKey(windowSize.width, windowSize.height, '11')} cellRenderer={
                        ({ cellData, rowIndex, dataKey }) => (
                          <Link to={`/player/${menRanking[rowIndex].id}`} className="player-link">
                            {cellData}
                          </Link>
                          )
                        } />
                        <Column width={150} label="Classifica" dataKey="class2024" key={generateKey(windowSize.width, windowSize.height, '12')} />
                        <Column width={150} label="Coefficiente" dataKey="coeff" key={generateKey(windowSize.width, windowSize.height, '13')} />
                        <Column width={150} label="Ranking" dataKey="ranking" key={generateKey(windowSize.width, windowSize.height, '14')} />
                      </Table>
                    )}
                  </AutoSizer>
                </div>
              </div>
              <div className="table-container">
                <h2 className="table-title">Top donne classifica generale</h2>
                <div style={{ height: '80vh', width: '90%', marginTop: '25px' }}>
                  <AutoSizer key={generateKey(windowSize.width, windowSize.height, '00')}>
                    {({ height, width }) => (
                      <Table
                        key={generateKey(windowSize.width, windowSize.height, '2')}
                        headerClassName={"table-header"}
                        gridClassName={"table-row"}
                        rowClassName={"table-row"}
                        width={width}
                        height={height}
                        headerHeight={30}
                        rowHeight={50}
                        rowCount={womenRanking.length}
                        rowGetter={({ index }) => womenRanking[index]}>
                        <Column width={300} label="Nome" dataKey="name" key={generateKey(windowSize.width, windowSize.height, '21')} cellRenderer={
                        ({ cellData, rowIndex, dataKey }) => (
                          <Link to={`/player/${womenRanking[rowIndex].id}`} className="player-link">
                            {cellData}
                          </Link>
                          )
                        } />
                        <Column width={150} label="Classifica" dataKey="class2024" key={generateKey(windowSize.width, windowSize.height, '22')} />
                        <Column width={150} label="Coefficiente" dataKey="coeff" key={generateKey(windowSize.width, windowSize.height, '23')} />
                        <Column width={150} label="Ranking" dataKey="ranking" key={generateKey(windowSize.width, windowSize.height, '24')} />
                      </Table>
                    )}
                  </AutoSizer>
                </div>
              </div>
            </div>
            <div className="footer">
              <div className="tooltip-wrap">
                <div>⒤</div>
                <div className="tooltip-content">Informazioni ricavate da <span className="underline">https://www.fitp.it/Campionati-tornei-e-classifiche/Classifiche/Info-e-documenti</span>
                  <br />
                  <div>Ultimo aggiornamento: 02/01/2024</div>
                </div>
              </div>
              <a href="mailto:ranking.fitp@gmail.com" className="m-l-50 brown">Contatto</a>
            </div>
          </div>
        }
      </main>
    )
  }
}
